import * as React from 'react';
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'

export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout<T = {}> = AppProps & {
  Component: NextPageWithLayout<T>
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  return <Component {...pageProps} />;
}
